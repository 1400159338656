html {
    height: 100%;
}

body {
    height: 100%;
}

#__next {
    height: 100%;
}